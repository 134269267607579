<template>
  <div class="col-12 col-md-10 offset-md-1 mx-auto">
    <alert v-if="loading" class="light-shadow" />
    <div v-else>
      <div class="row">
        <h1 class="header-font-size">
          <router-link :to="{ name: 'rooms' }" class="btn text-primary">
            <icon type="arrow-left fa-lg" />
          </router-link>
          All Rooms Recordings
        </h1>
      </div>
      <div
        v-if="!loading"
        class="card overflow-auto shadow-card mobile-tablet-no-card bg-white"
      >
        <div class="form-group mx-2 my-3" v-if="!loading">
          <input
            type="search"
            class="form-control pr-5"
            v-model.trim="searchContent"
            placeholder="Search by Room Name"
          />
          <span>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="searchContent = ''"
            ></i>
          </span>
        </div>
        <div class="custom-control custom-checkbox mx-2">
          <input
            type="checkbox"
            class="custom-control-input"
            id="displaySharedRecordingsOnly"
            v-model="displaySharedRecordingsOnly"
          />
          <label
            class="custom-control-label mr-3 mb-3"
            for="displaySharedRecordingsOnly"
          >
            Show only recordings that have been shared
          </label>
        </div>
        <alert
          class="my-3 mx-3 light-shadow"
          :hideLoader="true"
          v-if="filteredRecordings.length == 0"
        >
          No recordings to list.
        </alert>
        <div
          class="
            card
            mb-0
            shadow-card
            mobile-tablet-no-card
            p-2 p-md-0
            border-lg-0
          "
          v-if="filteredRecordings.length > 0"
        >
          <table
            class="
              table table-sm table-striped
              mb-0
              responsive-mobile-tablet-table
            "
          >
            <thead>
              <tr>
                <th>Room Name</th>
                <th>Recording Date</th>
                <th>Size</th>
                <th class="w-25 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(recording, index) in filteredRecordings" :key="index">
                <td>
                  <b class="mobile-tablet-only mr-2">Room Name: </b>
                  {{ recording.meeting.room_name }}
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Recording Date: </b>
                  {{ prettyDate(recording.recording_start) }}
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Size: </b>
                  {{ recording.size }}
                </td>
                <td class="td-actions text-center">
                  <button
                    title="View Recording"
                    class="btn btn-outline-blue mx-1 my-1"
                    @click="viewRecoding(recording)"
                  >
                    <span> <icon type="eye" /> </span>
                  </button>
                  <button
                    v-if="loggedInUser.isAdmin || loggedInUser.isManagingAdmin"
                    title="Download Recording"
                    class="btn btn-outline-blue mx-1 my-1"
                    @click="downloadRecording(recording)"
                  >
                    <span> <icon type="download" /> </span>
                  </button>
                  <button
                    v-if="loggedInUser.isAdmin || loggedInUser.isManagingAdmin"
                    title="Share Recording"
                    class="
                      btn btn-outline-blue
                      mx-1
                      my-1
                      share-recording-button
                    "
                    @click="shareRecordingHelper(recording)"
                  >
                    <span> <icon type="share" /> </span>
                    <span
                      class="counter-badge badge badge-primary"
                      v-if="
                        recording.shared_with && recording.shared_with.length
                      "
                      title="Number of users with whom the recording was shared"
                    >
                      {{
                        recording.shared_with.length > 99
                          ? "99+"
                          : recording.shared_with.length
                      }}
                    </span>
                  </button>
                  <button
                    title="Delete Recording"
                    class="btn btn-outline-danger mx-1 my-1"
                    @click="deleteRecordingHelper(recording)"
                  >
                    <span
                      v-if="deleteLoading == recording.path"
                      class="fa fa-spin fa-circle-notch"
                    ></span>
                    <span v-else> <icon type="trash" /> </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <recording-view-modal
      v-if="recording"
      :room-name="roomName"
      :recording="recording"
    />
    <share-recording-modal v-if="recording" :recording="recording" />
  </div>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
import recordingViewModal from "./RecordingViewModal.vue";
import shareRecordingModal from "./ShareRecordingModal.vue";
export default {
  name: "RoomsRecordings",
  components: {
    recordingViewModal,
    shareRecordingModal,
  },
  data() {
    return {
      deleteLoading: null,
      searchContent: "",
      recording: null,
      roomName: "",
      displaySharedRecordingsOnly: false,
    };
  },
  mounted() {
    this.getAllRecordings();
  },
  computed: {
    ...mapState({
      loading: (state) => state.rooms.recordings.loading,
      recordings: (state) => state.rooms.recordings.data,
      loggedInUser: (state) => state.auth.user,
    }),
    filteredRecordings: function () {
      return this.recordings
        .filter((recording) => {
          if (this.displaySharedRecordingsOnly && recording.shared_with) {
            return recording.shared_with.length > 0;
          } else {
            return true;
          }
        })
        .filter((recording) =>
          recording.meeting.room_name
            .toLowerCase()
            .includes(this.searchContent.toLowerCase())
        );
    },
  },
  methods: {
    ...mapActions({
      getAllRecordings: "rooms/getAllRecordings",
      deleteRecording: "rooms/deleteRecording",
      shareRecording: "rooms/shareRecording",
    }),
    downloadRecording: function (recording) {
      window.open(recording.url, "_blank");
    },
    deleteRecordingHelper: function (recording) {
      Swal.fire({
        title: "Are you sure?",
        html: `You will not be able to recover this recording! <br/> <br/> <span class="text-danger">Deleting this recording will also delete it from the users with whom it was shared.</span>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteLoading = recording.path;
          this.deleteRecording({
            meetingId: recording.meeting.id,
            data: {
              recording_path: recording.path,
            },
          }).then((res) => {
            if (res) {
              this.deleteLoading = null;
              this.getAllRecordings();
            }
          });
        }
      });
    },
    shareRecordingHelper: function (recording) {
      this.recording = recording;
      this.$nextTick(() => {
        this.$modal.show("shareRecordingModal");
      });
    },
    prettyDate: function (date, onlyDate = false) {
      if (onlyDate) {
        return dayjs(date).format("MMM DD, YYYY");
      } else {
        return dayjs(date).format("MMM DD, YYYY  hh:mm A");
      }
    },
    viewRecoding: function (recording) {
      this.recording = recording;
      this.roomName = recording.meeting.room_name;
      this.$nextTick(() => {
        this.$modal.show("recordingViewModal");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.9rem;
}
.header-font-size {
  font-size: 1.5rem;
  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
}
.form-group {
  position: relative;
}
.cancel-icon {
  z-index: 99;
  position: absolute;
  right: 13px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}

.share-recording-button {
  position: relative;
  .counter-badge {
    position: absolute;
    top: -8px;
    right: -5px;
  }
}
</style>
